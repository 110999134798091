<template>
    <div v-if="shop">
        <!-- CloseButton -->
        <n-close-button v-if="!isLoading" path="home" />

        <div class="p-3">
            <div class="top-container p-0">
                <div>
                    <h4 class="font-27 font-weight-500 text-black mb-1 mt-0">金額 ${{ cart.grandTotal }}</h4>
                    <div v-if="paymentMethodOptions.length > 1" class="subtitle font-weight-500 mb-3">請選擇下列付款方式。如非現金付款，請記下QR CODE。</div>
                    <div v-else class="subtitle font-weight-500 mb-3">請選擇付款方式一次付清。</div>
                </div>
            </div>
            <div>
                <div class="form b-b-1 pb-3">
                    <label class="radio-container" v-for="method in paymentMethodOptions" :key="method.value">
                        <input type="radio" v-model="cart.payment.method" :value="method.value"/>
                        <span class="font-19 text-black font-weight-700">{{ method.text }}</span>              
                    </label>
                </div>
                <div>
                
                    <img v-if="cart.payment.method == 'PAYME'" class="mb-3 overflow-y-hidden" :src="shop.imagePayme" />
                    <img v-if="cart.payment.method == 'FPS'" class="mb-3 overflow-y-hidden" :src="shop.imageFPS" />

                    <div class="check-container my-4 px-2">
                        <label class="control checkbox">
                            <input type="checkbox" id="checkbox" v-model="isAgree" />
                            <div class="control-indicator"></div>
                            <label class="control-label" for="checkbox">我已閱讀並同意<a class="text-blue font-weight-500 px-2" target="_blank">服務條款</a></label>
                            <div class="checkbox-alert text-red font-10 mb-2" v-if="!isAgree">您尚未勾選同意條款</div>
                        </label>
                    </div>
                    <button v-if="isAgree" class="btn primary-btn flex-center line-height-64 mb-3" :class="{ disabled: isLoading }" :disabled="isLoading" @click="onSubmit">
                        <div v-if="isLoading" class="lds-ring my-20 mx-auto">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <span v-else>付款</span>
                    </button>
                </div>
                <div class="mb-2">
                    <span class="px-2 mt-3 mb-20 text-dark-warm-grey">* 餐點不接受退換貨服務</span>
                </div>
                <div>
                    <span class="font-12 text-warm-grey">分店名稱：{{ shop.merchantName }} {{ shop.name }}</span
                    ><br />
                    <span class="font-12 text-warm-grey">分店地址：{{ shop.address }}</span
                    ><br />
                    <span class="font-12 text-warm-grey">分店電話：{{ shop.phone }}</span
                    ><br />
                </div>
            </div>
        </div>

        <form v-if="shop.hasRecon" ref="reconForm" method="post" action="https://recon-uat.cityline.com/ws/b2cPay">
            <input name="amt" v-model="reconPayRequest.amt" hidden />
            <input name="curr" v-model="reconPayRequest.curr" hidden />
            <input name="desc" v-model="reconPayRequest.desc" hidden />
            <input name="lang" v-model="reconPayRequest.lang" hidden />
            <input name="merCode" v-model="reconPayRequest.merCode" hidden />
            <input name="merRef" v-model="reconPayRequest.merRef" hidden />
            <input name="notifyUrl" v-model="reconPayRequest.notifyUrl" hidden />
            <input name="returnUrl" v-model="reconPayRequest.returnUrl" hidden />
            <input name="timeout" v-model="reconPayRequest.timeout" hidden />
            <input name="sign" v-model="reconPayRequest.sign" hidden />
            <input name="signType" v-model="reconPayRequest.signType" hidden />
            <input name="ver" v-model="reconPayRequest.ver" hidden />
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { sha256 } from '@/utils'
import moment from 'moment'

export default {
    data() {
        return {
            isAgree: false,
            isLoading: false,
            reconPayRequest: {
                amt: null,
                curr: 'HKD',
                desc: 'NubeTech Food Ordering',
                lang: 'tc',
                merCode: 'T7473735',
                merRef: null,
                notifyUrl: 'https://asia-east2-nube-dev-8514f.cloudfunctions.net/reconPayNotification',
                returnUrl: 'https://asia-east2-nube-dev-8514f.cloudfunctions.net/reconPayResponse',
                timeout: 10,
                sign: null,
                signType: 'SHA-256',
                ver: 1,
            },
        }
    },

    async created() {
        if (this.cart.products < 1) return this.$router.push(`/${this.shop.id}/home`)

        await this.$store.dispatch('bindDocument', { col: 'customers', id: localStorage.getItem(`${this.shop.merchantId}-customerId`), target: 'customer' })

        this.cart.payment = { method: null, paid: 0 }

        if (this.shop.hasRecon) this.reconPayRequest.amt = this.cart.grandTotal * 100
    },

    computed: {
        ...mapState({
            shop: state => state.shop,
            cart: state => state.cart,
            customer: state => state.customer,
        }),

        paymentMethodOptions() {
            let res = [{ text: '現金付款', value: 'CASH' }]
            if (this.shop.hasPayme) {
            res.push({ text: 'Payme', value: 'PAYME' })
            }
            if (this.shop.hasFPS) {
            res.push({ text: '轉數快', value: 'FPS' })
            }
            if (this.shop.hasRecon) {
            res.push({ text: '信用卡', value: 'RECON' })
            }
            return res
        }
    },

    methods: {
        async onSubmit() {
            this.isLoading = true

            this.cart.merchantId = this.shop.merchantId
            this.cart.merchantName = this.shop.merchantName

            this.cart.shop = {
                id: this.shop.id,
                name: this.shop.name,
                phone: this.shop.phone,
                address: this.shop.address,
            }

            this.cart.customer = {
                ...this.cart.customer,
                id: this.customer.id,
                name: this.customer.name,
                phone: this.customer.phone,
            }

            this.cart.unprint = true

            this.cart.createdAt = moment().format('X')
            this.cart.pendingAt = moment().format('X')

            if (this.cart.payment.method == 'RECON') {
                this.cart.status = 'PENDING_PAYMENT'
            }

            const res = await this.$store.dispatch('createOrder')
            this.$store.dispatch('resetCart')

            if (res.payment.method == 'RECON') {
                this.reconPayRequest.merRef = res.id

                let signText = `amt=${this.reconPayRequest.amt}&curr=${this.reconPayRequest.curr}&desc=${this.reconPayRequest.desc}&lang=${this.reconPayRequest.lang}&`
                signText += `merCode=${this.reconPayRequest.merCode}&merRef=${this.reconPayRequest.merRef}&notifyUrl=${this.reconPayRequest.notifyUrl}&returnUrl=${this.reconPayRequest.returnUrl}&`
                signText += `timeout=${this.reconPayRequest.timeout}&ver=${this.reconPayRequest.ver}&8a32a0b9-ec11-41f9-938e-c4a9c8c93e63`

                this.reconPayRequest.sign = sha256(signText)

                setTimeout(() => {
                    this.$refs.reconForm.submit()
                }, 500)
            } else {
                this.$router.push(`/${this.shop.id}/order/${res.id}`)
            }
        },
    },
}
</script>
